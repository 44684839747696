@import "../../../styles/ta_variables";

.topmenu {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 18vh;
    background-color: #ff8018;
    color: white;
}

.topmenu-action {
    @extend .topmenu;
    margin-top: -18vh;
    transform: translateY(-100%);
    position: relative;
    background-color: $M03;
    animation-name: slideDown;
    animation-duration: 350ms;
    animation-delay: 360ms;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}

.topmenu-header {
    font-size: 2vh;
}

.topmenu-header-row {
    height: 50%;
}

.button-select-all {
    position: absolute;
    margin-left: 30vw;
}

.icon-select-all {
    max-height: 12vh;
}

.icon {
    &_left {
        margin-right: 3vw;
        max-height: 12vh;
    }
}

.container {
    margin-left: 3vw;
}

.button {
    display: flex;
    height: 100%;
    width: 10vw;
    min-width: 70px;
    justify-content: center;
    align-items: center;
}

.button_selected {
    @extend .button;
    background-color: #ff8018;
}

.button-icon {
    max-height: 12vh;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}
