.k42-login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.k42-login-dial-pad-switch {
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
}
