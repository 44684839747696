.k42-app {
  height: 100%;
}

.k42-prompt-rotate {
  width: 100vw;
  height: 100vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.k42-login-logo {
  width: 50vw;
  margin-bottom: 15vh;
}


// remove when https://github.com/contra/react-responsive/issues/197 is resolved
@media only screen and (orientation: landscape) {
  .k42-prompt-rotate {
    display: none;
  }
}