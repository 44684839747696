.k42-error {
  padding-left: 96px;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: red;
}

.k42-login-logo {
  width: 192px;
  margin-bottom: 24px;
}
