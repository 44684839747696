@import "../../styles/variables";

.k42-job-list {
    height: 82vh;
    overflow-y: scroll;
}

.k42-hidden {
    display: none !important;
}

.k42-row-column {
    min-width: 0 !important;
    flex-direction: column !important;
    align-items: baseline !important;
}

.k42-item-label {
    font-size: 16px;
    font-weight: 600;
}

.k42-item-label-checked {
    color: #32af95 !important;
}

.k42-item-value {
    font-size: 12px;
    color: grey;
    text-transform: uppercase;
}

.k42-job-icon {
    width: 3vw;
    min-width: 30px;
}

.k42-nojobs {
    color: black;
    font-size: 4vh;
    display: flex;
    height: 82vh;
    justify-content: center;
    align-items: center;
}

.k42-carousel-list-item {
    cursor: pointer !important;
    padding-left: 0 !important;
}

.k42-swipe {
    display: flex;
    padding: 0px;
}

.k42-swipe-print {
    @extend .k42-swipe;
    background-color: $M00;
    padding-right: 2vw !important;
}

.k42-swipe-favorite {
    @extend .k42-swipe;
    width: 15vw;
    justify-content: center;
    background-color: $M08;
}

.k42-swipe-favorite-full {
    @extend .k42-swipe-favorite;
    width: 100vw;
    justify-content: flex-start;
    padding-left: 5vw;
}

.k42-swipe-delete {
    @extend .k42-swipe;
    background-color: $M05;
    padding-left: 4vw !important;
}
