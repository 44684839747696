.flagContainer {
  position: absolute;
  bottom: 15px;
  right: 17px;
  
}

.flag {
  overflow: hidden;
  background-color: #ccc;
  box-shadow: 0px 0px 300px darkgray;
  border: 6px solid white;
  border-radius: 100%;
  cursor: pointer;
  width: 45px;
  height: 45px;
  
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  animation: fadeIn 1s both;
  box-shadow: 0px 0px 100px 0px rgba(0,0,0,0.5);
}

@media only screen and (max-height: 499px) { 
  .flag {
    width: 35px !important;
    height: 35px !important;
  }
}

.listWrapper {
  width: fit-content;
  border-radius: 12px;
  z-index: 1001;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  bottom: 70px;
  right: 0px;
  height: fit-content;
}

.list {
  margin: 0;
  list-style: none;
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
}

.language {
  padding: 0;
}

li {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  color: black;
  align-items: center;
}

li span {
  margin-left: 5px;
}
