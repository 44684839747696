@import "../../../styles/ta_variables";

.bubble {
    padding: 24px !important;
    max-width: 40vw !important;

    @media screen and (max-height: 499px) {
        padding: 4vh !important;
    }
}

.parent {
    font-size: 16px;

    @media screen and (max-height: 499px) {
        font-size: 3vh;
    }
}

.title {
    font-size: 110%;
}

.body {
    background-color: rgb(255, 255, 255);
    border-radius: 0.9em;
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    padding: 1.1em;
    position: relative;
    max-width: 40vw;
    line-height: 1.4;
    text-align: left;
}

.content {
    padding: 0 0px 1.1em 1.1em;
}

.buttonRow {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
}

.primaryButton {
    background-color: $brand;
    border: 0px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    line-height: 1;
    padding: 0.7em;
    -webkit-appearance: none;
    margin-left: 12px;
}

.secondaryButton {
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    color: rgb(51, 51, 51);
    cursor: pointer;
    font-size: 90%;
    line-height: 1;
    padding: 8px;
    -webkit-appearance: none;
}

.spacer {
    flex: 1 1 0%;
}


.attachmentImage {
    z-index: -1;
    position: absolute;
    bottom: -160px;
    right: -250px;
    height: 300px;
}

.arrow {
    display: inline-flex;
    position: absolute;
    right: -15px;
    margin-top: 8px;
    margin-bottom: 3px;
    bottom: 16px;
}

.infoBox {
    font-size: 90%;
    text-align: left;
    position: relative;
    margin: 0 0px 24px 0px;
    border-radius: 4px;
    padding: 0.8em 0.8em 0.8em 3.5em;
    border: dashed lightgray 2px;
}

.infoIcon {
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    left: 1em;
    top: 1em;
}

.autoFillContainer {
    text-align: center;
}

.autoFillBox {
    font-weight: bold;
    background-color: lightgray;
    font-family: monospace;
    text-align: center;
    position: relative;
    margin: 0 0px 1.2em 0px;
    border-radius: 4px;
    padding: 12px;
}

.autoFillText {
    text-transform: uppercase;
    font-size: 80%;
    font-weight: bold;
    color: #000;
}
