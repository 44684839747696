.k42-navbar {
    display: flex;
    position: fixed;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 18vh;
    background-color: white;
}

.k42-navbar-logo {
    margin-right: 1.5vw;
    max-height: 12vh;
}

.k42-navbar-header {
    font-size: 6vh;
}

.k42-navbar-logo-container {
    margin-left: 4vw;
    margin-right: 12px;
}

.k42-navbar-icon {
    margin-right: 4vw;
    max-height: 14vh;
}
