@import "../../styles/animations";

.arrow {
  display: inline-flex;
  position: absolute;
  right: -15px;
  margin-top: 8px;
  margin-bottom: 3px;
  bottom: 16px;
}

.body {
  z-index: 1;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 24px;
  position: relative;
  width: 380px;
  line-height: 1.4;
  text-align: left;
  animation: fadeIn 0.5s both;
}

.closeButton {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 24px;
  -webkit-appearance: none;
  position: absolute;
  right: 0px;
  top: 0px;
}
