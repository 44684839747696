@import "../../../styles/animations";
@import "../../../styles/ta_variables";

.container {
    position: absolute;
    bottom: 30px;
    right: 60px;
    min-width: 220px;
    z-index: 1000;
  
    @media screen and (max-height: 499px) {
      bottom: 7vh;
      right: 20vh;
    }
    @media screen and (max-width: 1200px) {
      bottom: 6vh !important;
      right: 15vh !important;
    }
  }

.primaryButton {
    background-color: $brand;
    border: 0px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    line-height: 1;
    padding: 12px;
    -webkit-appearance: none;
    margin-left: 12px;
}

.buttonRow {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 42px;
}

.avatar {
    cursor: pointer;
    bottom: 0;
    right: 0;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    position: absolute;
    overflow: hidden;
    background-color: #ccc;
    box-shadow: 0px 0px 300px darkgray;
    border: 6px solid white;
    animation: fadeIn 1s both;

    @media screen and (max-height: 499px) {
        height: 16vh;
        width: 16vh;
    }
}

.img {
    position: absolute;
    width: 100%;
}

.bubble {
    position: absolute;
    right: 120px;
    bottom: 11px;
    animation: bounceIn 1s both;

    @media screen and (max-height: 499px) {
        right: 25vh;
        bottom: 0;
    }
}

.link {
    margin-top: 16px;
}
