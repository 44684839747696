
.k42-tile {
  padding: 2vh;
  margin: 0.5vh;
  flex: 1 0 20vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  max-height: 21vh;
  width: 30vw;
  font-size: 1.5vw;
}

.k42-tile-logo {
  max-height: 10vh;
}

.k42-tile-attachment {
  font-size: 3vw;
  margin: 0.5em 0 0 0.5em;
}

.k42-tile-title {
  align-self: flex-start;
}

.k42-tile-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .k42-tile {
    font-size: 12px;
  }

  .k42-tile-attachment {
    font-size: 12px;
  }
}
