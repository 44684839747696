.k42-application-list {
  padding-top: 18vh;
  padding-bottom: 10vh;
  padding-left: 10vh;
  height: 82vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  flex-wrap: wrap;
  background-color: black;

  p {
    padding: 0;
    margin: 8px 0 0 0;
    font-weight: normal;
    font-size: 2em;
  }

  img {
    margin-left: -3px;
  }
  
}


