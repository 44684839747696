// PRINT
.k42-print-enter-active > .ons-swiper-target {
  transform: translate3d(0px, 0px, 0px) !important;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0.7, 0.5, 1);
}

// FAVORITE
.k42-favorite-enter-active > .ons-swiper-target {
  transform: translate3d(-200vw, 0px, 0px) !important;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0.7, 0.5, 1);
}

.k42-favorite-enter-active
  > .ons-swiper-target
  > ons-carousel-item
  > ons-list-item
  > .swipe-favorite {
  width: 100vw;
  justify-content: flex-start;
  padding-left: 5vw;
}

.k42-favorite-enter-active
  > .ons-swiper-target
  > ons-carousel-item
  > ons-list-item
  > .swipe-delete {
  display: none !important;
}

// DELETE
.k42-delete-enter-active > .ons-swiper-target {
  transform: translate3d(-200vw, 0px, 0px) !important;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0.7, 0.5, 1);
}
.k42-delete-enter-active
  > .ons-swiper-target
  > ons-carousel-item
  > ons-list-item
  > .swipe-delete {
  width: 100vw;
  justify-content: flex-start;
  padding-left: 5vw;
}

.k42-delete-enter-active
  > .ons-swiper-target
  > ons-carousel-item
  > ons-list-item
  > .swipe-favorite {
  display: none !important;
}
