.k42-application-list {
  padding-top: 18vh;
  padding-bottom: 10vh;
  height: 82vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-wrap: wrap;
  background-color: #1d253d;
}

.k42-application-icon {
  display: flex;
  width: 15vw;
  max-height: 40vh;
}

.k42-application-icon-img {
  align-self: center;
  max-height: 41vh;
  max-width: 15vw;
}
