@import "../../../styles/ta_variables";

.k42-login-row .k42-dial-button {
    width: 100%;
    border-radius: 0;
    height: 12vh;
    line-height: 12vh;
    overflow: hidden;
    font-weight: 800;
    font-size: 3vh;
    color: black;
}

.k42-login-row {
    border-bottom: white 1px solid;
    flex-wrap: nowrap;
    border-bottom: 2px solid #dcdcdc;
}

.k42-login-row:last-child {
    border-bottom: 0;
}

.k42-login-row:last-child > ons-col:last-child > .k42-dial-button {
    color: $M00;
    padding-top: calc(12vh / 2 - 54px / 2);
}

.k42-login-row:last-child > ons-col:first-child > .k42-dial-button {
    padding-top: calc(12vh / 2 - 54px / 2);
}
