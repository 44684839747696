.k42-dashboard {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  margin-top: 18vh;
  height: 82vh;
  padding-left: 25px;
  overflow-y: hidden;
  min-width: 0;
  overflow-x: auto;
}

@media only screen and (max-width: 1200px) {
  .k42-dashboard {
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;
    margin-top: 18vh;
    height: 82vh;
    padding-left: 11px !important;
    overflow-y: auto;
    min-width: 0;
    overflow-x: auto;
  }
}

@media only screen and (max-width: 750px) {
  .k42-dashboard {
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;
    margin-top: 18vh;
    height: 82.2vh;
    padding-left: 5px !important;
    overflow-y: scroll;
    min-width: 0;
    overflow-x: auto;
  }
}
