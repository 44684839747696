.k42-login-logo {
  width: 36vh;
  margin-left: 0vh;
  margin-bottom: 4vh;
}

.k42-poweredBy-logo-for-wietholt {
  width: 29vh;
  margin-left: 2vh;
  margin-bottom: 4vh;
}

ons-card.k42-login-card {
  background-color: transparent;
  width: 80vw;
  max-height: 100vh;
  max-width: 100vw;
  box-shadow: none;
}

.k42-login-input {
  display: block;
  height: 12vh;
  margin: 2px 0px;
  padding-left: 0.66vw;
  font-size: 3vh;
  border: 2px solid #c8c8c8 !important;
  width: calc(100% - 1vw);
}

.k42-login-input-mobile {
  @extend .k42-login-input;
  padding-left: 0;
  width: 100%;

  input {
    padding: 0;
    border: 0;
    padding-left: 1vw;
    height: 100%;
    width: calc(100% - 1vw);
    margin: 0;
  }
}

.k42-login-input:first-child {
  margin-top: 5vh;
}

ons-button.k42-login-button {
  display: block;
  height: 12vh;
  line-height: 12vh;
  border-radius: 0;
  background-color: #f9be5c;
  font-size: 3vh;
  width: 100%;
}

.k42-login-disclaimer {
  display: block;
  margin-top: 1.2vh;
  font-size: 1vw;
  color: black;
}

.k42-login-col {
  min-width: 33vw;
  margin: 0 0vh;
  position: relative;
}

.k42-login-form {
  margin: 6vh 0 0 0;
}

.k42-dialpad {
  margin: 0 6vh;
}

.k42-login-input-icon {
  position: absolute;
  color: gainsboro;
  height: 8vh;
  top: 8vh;
  right: 0vw;
}

.k42-login-input-icon:nth-child(2) {
  top: 20vh;
}
