.k42-dialog-item {
  min-width: 0 !important;
  flex-direction: column !important;
  align-items: baseline !important;
}

.k42-required:before {
  content: "*";
  font-size: 14px;
  padding-right: 3px;
  padding-top: 3px;
  position: relative;
  color: red;
  align-self: baseline;
}

.k42-dialog-list-item {
  font-size: 12px;
}

.k42-dialog-list-bold-item {
  font-size: 12px;
  font-weight: 800;
}

.k42-dialog-toolbar {
  box-shadow: none !important;
}

.k42-dialog-menu-page,
.k42-dialog-list,
.k42-dialog-prompt {
  bottom: 48px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.k42-dialog-prompt {
  width: 50vw;
  height: 10vh;
  min-width: 400px;
  min-height: 200px;
}

.k42-dialog-alert {
  min-width: 400px;
  min-height: 200px;
}

.k42-dialog-password {
  width: 50vw;
  height: 30vh;
  min-width: 400px;
  min-height: 200px;
}

.k42-dialog-list {
  width: 50vw;
  height: 30vh;
  min-width: 400px;
  min-height: 100%;
}

.k42-dialog-numberinput {
  width: 50vw;
  height: 30vh;
}

.k42-dialog-prompt-box {
  display: flex;
  flex-direction: row;
  padding: 0 30px;
  align-items: center;
  justify-content: flex-start;
}

.k42-dialog-prompt-label {
  font-size: 14px;
  margin-right: 30px;
  padding-top: 3px;
  color: black;
  min-width: 20vw;
}

.k42-dialog-prompt-input {
  margin: 0px 12px;
  width: calc(100% - 24px);
}

.k42-dialog-tabs {
  width: 50vw;
  height: 50vh;
  min-width: 400px;
  min-height: 400px;
}

.k42-dialog-item-value {
  color: grey;
  text-transform: uppercase;
}

.k42-dialog-item-checkbox {
  margin-right: 4px;
}

.k42-dialog-item-incrementor {
  margin: 0 1rem !important;
  background-color: transparent !important;
  border: 2px solid #32af95 !important;
  color: #32af95 !important;
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  line-height: 27px !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
}

.k42-dialog-item-incrementor:disabled,
.k42-dialog-item-incrementor[disabled] {
  border: 2px solid #aaaaaa !important;
  color: #aaaaaa !important;
}

.k42-dialog-item-text-input {
  width: 1.1rem;
  text-align: center;
}
