.k42-qr-content {
  width: 100%;
  height: 48vh !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .k42-qr-btn {
    width: 100%;
  }

  .k42-qr-image {
    width: 100%;
  }
}
