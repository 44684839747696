
.k42-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: gray;
}

.k42-animation {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.k42-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.k42-image-container:first-child {
  @media only screen and (max-width: 992px) {
    width: 75%;
    height: 75%;
  }

  width: 90%;
  height: 90%;

}

.k42-image-container > * > img {
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
  width: 100%;
  height: 100%;
}

.k42-image-container > * > span {
  position: absolute !important;
  font-size: 18px;

  @media only screen and (max-width: 1400px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 992px) {
    font-size: 8px !important;
  }


}

.k42-image-container > * > span:first-of-type {
  left: 21.7% !important;
  top: 45.3%;

  @media only screen and (max-width: 992px) {
    top: 43%
  }
}


.k42-text-over {
  position: absolute;
  z-index: 3;
  font-family: "Segoe";
  color: black;
  font-size: 12px;
  left: 100px;
  top: 132px;
}

.k42-text-over-2 {
  position: absolute;
  z-index: 3;
  left: 100px;
  top: 152px;
  font-family: "Segoe";
  color: black;
}

.k42-page {
  z-index: 2;
  max-height: 100%;
}

.k42-watermark {
  position: absolute;
  color: black;
  left: 53%;
  bottom: 40%;
  opacity: 0.3;
  font-size: 7rem;
  transform: translate(-50%) rotate(-33deg);
  z-index: 2;
  @media screen and (max-width: 1300px) {
    font-size: 5rem;
  }
  @media screen and (max-width: 1000px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }
}

.k42-pdf-protection-scan {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;
  height: 75%;
}

.k42-close-button {
  position: fixed;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  background-color: rgba(255, 251, 234, 0.0);
  border: none;
  border-radius: 100%;
  z-index: 99;
}

.k42-page-print {
  @extend .k42-page;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-name: print-all;
}

.k42-page-pdf-pass {
  @extend .k42-page;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-name: print-all;
}

.k42-page-pdf-appear {
  @extend .k42-page;
  animation: fade-in ease 0.5s;
}

.k42-page-scan {
  @extend .k42-page;
  z-index: 5;
  position: fixed;
  height: 100%;
  width: 100%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b2b2b2+40,fffbea+50,aaaaaa+60&0+40,1+50,0+60 */
  background: -moz-linear-gradient(W left,
          rgba(178, 178, 178, 0) 0%,
          rgba(255, 251, 234, 1) 5%,
          rgba(170, 170, 170, 0) 10%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
                  left,
                  rgba(178, 178, 178, 0) 0%,
                  rgba(255, 251, 234, 1) 5%,
                  rgba(170, 170, 170, 0) 10%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
                  to right,
                  rgba(178, 178, 178, 0) 0%,
                  rgba(255, 251, 234, 1) 5%,
                  rgba(170, 170, 170, 0) 10%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b2b2b2', endColorstr='#00aaaaaa', GradientType=1); /* IE6-9 */
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: scan;
}

.k42-classification-scan {
  @extend .k42-page-scan;
  animation-duration: 2s;
  z-index: 5;
}

@keyframes scan {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 100vw;
  }
}

@keyframes print-all {
  from {
    margin-left: -100vw;
  }
  to {
    margin-left: 0;
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
